<template>
  <v-container fluid>
    <v-expansion-panel focusable>
      <v-expansion-panel-content class="mycustomExpansionPanel">
        <template v-slot:actions>
          <v-icon large color="primary">$vuetify.icons.expand</v-icon>
        </template>
        <template v-slot:header>
          <div>
            <v-icon color="primary">search</v-icon>
            <span class="pl-2 subheading">Custom Search Panel</span>
          </div>
        </template>
        <v-layout row wrap align-center>
          <v-flex>
            <v-card class="px-3">
              <v-layout row wrap align-center class="py-3">
                <v-flex class="mx-3" xs12 sm12 md12>
                  <v-text-field
                    label="Registration Number"
                    prepend-icon="list"
                    v-model="searchParam.ex_reg_no"
                    type="number"
                    single-line
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm12 md12 style="margin-top: 40px">
                  <v-layout justify-center row fill-height align-center>
                    <v-btn
                      color="primary"
                      large
                      @click="fetchCenterTransferExamineeList()"
                      >Search</v-btn
                    >
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-card color="white" style="margin-top: 40px">
      <v-layout row>
        <v-card flat>
          <v-card-text class="title">Center Transfer List</v-card-text>
        </v-card>
        <v-divider class="my-2" vertical></v-divider>
        <v-spacer />
        <v-layout row justify-end>
          <v-dialog
            v-model="dialog"
            persistent
            max-width="1100"
            transition="dialog-bottom-transition"
          >
            <template v-slot:activator="{ on }">
              <v-btn color="primary" dark v-on="on"
                ><v-icon size="30" color="#fff">repeat</v-icon>&nbsp;New Center
                Transfer</v-btn
              >
            </template>
            <v-card style="text-align: center">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Input Center Transfer Data</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn
                    fab
                    class="ma-1 pa-0"
                    small
                    color="red darken-3 white--text"
                    @click="clearFormData()"
                    ><v-icon>close</v-icon></v-btn
                  >
                </v-toolbar-items>
              </v-toolbar>
              <v-layout row class="py-3" wrap align-center>
                <v-flex class="mx-4" xs12 sm12>
                  <v-text-field
                    label="Registration Number"
                    prepend-icon="list"
                    v-model="searchParam.reg_no"
                    type="number"
                    single-line
                    style="margin-right: 120px"
                  ></v-text-field>
                </v-flex>
                <v-flex xs2 sm2 style="margin-left: 84%; margin-top: -6%">
                  <v-btn
                    class="ma-1 pa-0"
                    color="indigo darken-3 white--text"
                    @click="searchCenterTransferExamineeInfo()"
                  >
                    <v-icon>search</v-icon>
                  </v-btn>
                </v-flex>
              </v-layout>
              <v-layout row style="padding-left: 3px">
                <v-container
                  fluid
                  v-if="
                    getCenterTransferExamineeInformation != '' && not_found == 1
                  "
                >
                  <table class="b">
                    <tr>
                      <th
                        class="a"
                        align="left"
                        width="500"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Degree Code and Name
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <p
                          style="
                            margin-left: 10px;
                            margin-top: 14px;
                            margin-bottom: 14px;
                          "
                        >
                          {{ getCenterTransferExamineeInformation.degree_code }}
                          -
                          {{ getCenterTransferExamineeInformation.degree_name }}
                        </p>
                      </td>
                      <th
                        class="a"
                        align="left"
                        width="400"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Subject Code and Name
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <p
                          style="
                            margin-left: 10px;
                            margin-top: -10px;
                            margin-bottom: -10px;
                          "
                        >
                          {{
                            getCenterTransferExamineeInformation.subject_code
                          }}
                          -
                          {{
                            getCenterTransferExamineeInformation.subject_name
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th class="a" align="left">
                        <h3 style="margin-left: 10px; margin-right: 0px">
                          Name of the Examinee
                        </h3>
                      </th>
                      <td
                        class="a"
                        width="1000"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <v-text-field
                          v-if="
                            getCenterTransferExamineeInformation.student_name
                          "
                          v-model="
                            getCenterTransferExamineeInformation.student_name
                          "
                          style="
                            margin-left: 10px;
                            margin-top: -10px;
                            margin-bottom: -10px;
                          "
                          disabled
                        ></v-text-field>
                      </td>
                      <th
                        class="a"
                        align="left"
                        width="240"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Roll No
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <p
                          style="
                            margin-left: 10px;
                            margin-right: 50px;
                            margin-top: 10px;
                          "
                        >
                          {{ getCenterTransferExamineeInformation.exam_roll }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th class="a" align="left">
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Father's Name
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <v-text-field
                          v-model="
                            getCenterTransferExamineeInformation.father_name
                          "
                          style="
                            margin-left: 10px;
                            margin-top: -10px;
                            margin-bottom: -10px;
                          "
                          disabled
                        ></v-text-field>
                      </td>
                      <th
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Registration No
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <p
                          style="
                            margin-left: 10px;
                            margin-right: 50px;
                            margin-top: 10px;
                          "
                        >
                          {{ getCenterTransferExamineeInformation.reg_no }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th class="a" align="left">
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Mother's Name
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <v-text-field
                          v-model="
                            getCenterTransferExamineeInformation.mother_name
                          "
                          style="
                            margin-left: 10px;
                            margin-top: -10px;
                            margin-bottom: -10px;
                          "
                          disabled
                        ></v-text-field>
                      </td>
                      <th
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Session
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <p
                          style="
                            margin-left: 10px;
                            margin-top: -10px;
                            margin-bottom: -10px;
                          "
                        >
                          {{
                            getCenterTransferExamineeInformation.session_year
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          College Code and Name
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                        colspan="3"
                      >
                        <p
                          style="
                            margin-left: 10px;
                            margin-top: 14px;
                            margin-bottom: 14px;
                          "
                        >
                          {{
                            getCenterTransferExamineeInformation.college_code
                          }}
                          -
                          {{
                            getCenterTransferExamineeInformation.college_name
                          }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                      >
                        <h3 style="margin-left: 10px; margin-right: 50px">
                          Current Center Code and Name
                        </h3>
                      </th>
                      <td
                        class="a"
                        align="left"
                        style="margin-left: 10px; margin-right: 50px"
                        colspan="3"
                      >
                        <p
                          style="
                            margin-left: 10px;
                            margin-top: -10px;
                            margin-bottom: -10px;
                          "
                        >
                          {{ getCenterTransferExamineeInformation.center_code }}
                          -
                          {{ getCenterTransferExamineeInformation.center_name }}
                        </p>
                      </td>
                    </tr>
                  </table>
                </v-container>
              </v-layout>

              <v-layout
                row
                class="py-3"
                v-if="
                  getCenterTransferExamineeInformation != '' && not_found == 1
                "
              >
                <v-flex class="mx-3" xs12 sm12 md12>
                  <v-autocomplete
                    :disabled="ifCenterUser"
                    style="
                      margin-left: 10px;
                      margin-top: -10px;
                      margin-bottom: -10px;
                    "
                    v-model="searchParam.center_code"
                    :item-text="
                      (item) => item.center_code + ' - ' + item.center_name
                    "
                    item-value="center_code"
                    :items="getTheoryCenterList"
                    menu-props="auto"
                    label="Select New Center"
                    prepend-icon="list"
                    outline
                    @change="setCenterCollegeCode"
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        close
                        @input="clearCenter()"
                        :selected="data.selected"
                        class="chip--select-multi"
                      >
                        {{
                          data.item.center_code + " - " + data.item.center_name
                        }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template>
                        <v-list-tile-content>
                          
                          <v-list-tile-title>
                            {{
                              data.item.center_code +
                              " - " +
                              data.item.center_name
                            }}
                          </v-list-tile-title>
                        </v-list-tile-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-flex>
              </v-layout>
              <v-btn
                v-if="
                  getCenterTransferExamineeInformation != '' && not_found == 1
                "
                color="primary"
                dark
                style="margin-bottom: 20px"
                @click="submitCenterTransferExamineeInfo()"
                ><v-icon size="30" color="#fff">done</v-icon>&nbsp;Submit</v-btn
              >

              <div
                v-if="not_found == 3"
                style="text-align: center; padding-bottom: 30px"
              >
                <h1 style="font-size: 28px; color: #b71c1c">
                  Please Search With Registration Number !!!
                </h1>
              </div>

              <div
                v-if="not_found == 0"
                style="text-align: center; padding-bottom: 30px"
              >
                <h1>
                  <h1 style="font-size: 28px; color: #b71c1c">
                    No data Found !!!
                  </h1>
                </h1>
              </div>
            </v-card>
          </v-dialog>
        </v-layout>
      </v-layout>
    </v-card>

    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="getCenterTransferExamineeDataList"
      class="elevation-1"
    >
      <template v-slot:items="props">
        <tr>
          <td class="text-xs-center">{{ props.index + 1 }}</td>
          <td class="text-xs-center">{{ props.item.reg_no }}</td>
          <td class="text-xs-center">{{ props.item.exam_roll_no }}</td>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.previous_center_code }} -
            {{ props.item.previous_center_name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.current_center_code }} -
            {{ props.item.current_center_name }}
          </td>
          <td class="text-xs-center">
            <v-btn
              fab
              class="ma-1 pa-0"
              small
              color="red darken-3 white--text"
              disabled
              @click="deleteItem(props.item.id)"
              ><v-icon>delete</v-icon></v-btn
            >
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import commonGet from "../../mixin/common_getters_mixin";
import commonExamYear from "../../mixin/exam_year_watch_mixin";
import common_login_getters from "../../mixin/common_login_getters";
export default {
  mixins: [commonGet, commonExamYear, common_login_getters],

  data() {
    return {
      not_found: 3,
      selectedCenter: "",
      center_college_code: "",
      pagination: {
        rowsPerPage: 10,
        rowsPerPageItems: [10, 25, { text: "All", value: -1 }],
      },
      loading: false,
      dialog: false,
      currentItem: {},
      search: "",
      sl_count: -1,
      searchParam: {},
      headers: [
        {
          text: "SL.",
          align: "left",
          sortable: false,
          value: "rn",
        },
        {
          text: "Registration No",
          value: "reg_no",
          align: "center",
        },
        {
          text: "Exam Roll",
          value: "exam_roll",
          align: "center",
        },
        {
          text: "Name",
          value: "name",
          align: "left",
        },
        {
          text: "Previou Center Code & Name",
          value: "center_code",
        },
        {
          text: "New Center Code & Name",
          value: "center_code",
        },
        { text: "Actions", align: "center", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    if (this.ifCenterUser) {
      console.log("center code ????");
      this.searchParam.center_code = this.getAuthUser.center.center_code;
    }
    this.searchParam.exam_code = this.getLoggedInExam.code;
    this.searchParam.exam_year = this.getLoggedInYear.year;
    this.searchParam.exam_initiate_id = this.getExamInitiateId;
    this.fetchCenterTransferExamineeList();

    let param = {};
    param.exam_initiate_id = this.getExamInitiateId;
    param.exam_code = this.getLoggedInExam.code;
    param.year = this.getLoggedInYear.year;
    this.$store.dispatch("fetchTheoryWiseCenterData", param);
  },
  computed: {
    getCenterTransferExamineeInformation() {
      return this.$store.getters.CenterTransferExamineeInfoDataSet;
    },

    getCenterTransferExamineeDataList() {
      return this.$store.getters.CenterTransferExamineeList;
    },
  },
  watch: {},
  methods: {
    deleteItem(item) {
      const index = item.rn;
      var self = this;
      this.$root
        .$confirm("Are you sure that you want to delete?")
        .then((confirm) => {
          if (confirm) {
            this.$store
              .dispatch("deleteCenterTransferExamineeList", index)
              .then(function (data) {
                self.fetchCenterTransferExamineeList();
              })
              .catch(function (error) {
                console.log(error);
                self.fetchCenterTransferExamineeList();
              });
          }
        });
    },

    clearCenter() {
      this.searchParam.center_code = "";
    },

    searchCenterTransferExamineeInfo() {
      this.loading = true;
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.exam_year = this.getLoggedInYear.year;
      param.reg_no = this.searchParam.reg_no;
      this.$store
        .dispatch("getCenterTransferExamineeInfo", param)
        .then((data) => {
          this.loading = false;
          this.dialog = true;
          if (data.status == "error") {
            this.getCenterTransferExamineeInformation.degree_code = "";
            this.getCenterTransferExamineeInformation.degree_name = "";
            this.getCenterTransferExamineeInformation.subject_code = "";
            this.getCenterTransferExamineeInformation.subject_name = "";
            this.getCenterTransferExamineeInformation.student_name = "";
            this.getCenterTransferExamineeInformation.student_name = "";
            this.getCenterTransferExamineeInformation.exam_roll = "";
            this.getCenterTransferExamineeInformation.father_name = "";
            this.getCenterTransferExamineeInformation.reg_no = "";
            this.getCenterTransferExamineeInformation.mother_name = "";
            this.getCenterTransferExamineeInformation.session_year = "";
            this.getCenterTransferExamineeInformation.college_code = "";
            this.getCenterTransferExamineeInformation.college_name = "";
            this.getCenterTransferExamineeInformation.center_code = "";
            this.getCenterTransferExamineeInformation.center_name = "";
            this.not_found = 0;
          } else {
            this.not_found = 1;
            console.log(
              "Tanvir data of Center Transfer Examinee Info-->",
              this.not_found
            );
          }
        });
    },

    clearFormData() {
      this.dialog = false;
      this.searchParam.reg_no = "";
      this.getCenterTransferExamineeInformation.degree_code = "";
      this.getCenterTransferExamineeInformation.degree_name = "";
      this.getCenterTransferExamineeInformation.subject_code = "";
      this.getCenterTransferExamineeInformation.subject_name = "";
      this.getCenterTransferExamineeInformation.student_name = "";
      this.getCenterTransferExamineeInformation.student_name = "";
      this.getCenterTransferExamineeInformation.exam_roll = "";
      this.getCenterTransferExamineeInformation.father_name = "";
      this.getCenterTransferExamineeInformation.reg_no = "";
      this.getCenterTransferExamineeInformation.mother_name = "";
      this.getCenterTransferExamineeInformation.session_year = "";
      this.getCenterTransferExamineeInformation.college_code = "";
      this.getCenterTransferExamineeInformation.college_name = "";
      this.getCenterTransferExamineeInformation.center_code = "";
      this.getCenterTransferExamineeInformation.center_name = "";
      this.searchParam.center_code = "";
      this.not_found = 3;
    },

    setCenterCollegeCode(centerCode) {
      const selectedCenter = this.getTheoryCenterList.find(
        (item) => item.center_code === centerCode
      );
      this.selectedCenter = selectedCenter;
      this.searchParam.center_college_code = selectedCenter.center_college_code;

      console.log("Selected Center:", this.searchParam.center_college_code);
      // return any value you need
      return this.searchParam.center_college_code;
    },

    submitCenterTransferExamineeInfo() {
      this.loading = true;
      let Saveparam = {};
      Saveparam.exam_initiate_id = this.getExamInitiateId;
      Saveparam.exam_code = this.getLoggedInExam.code;
      Saveparam.exam_year = this.getLoggedInYear.year;
      Saveparam.reg_no = this.searchParam.reg_no;
      Saveparam.st_college_code =
        this.getCenterTransferExamineeInformation.college_code;
      Saveparam.degree_code =
        this.getCenterTransferExamineeInformation.degree_code;
      Saveparam.subject_code =
        this.getCenterTransferExamineeInformation.subject_code;
      Saveparam.session_year =
        this.getCenterTransferExamineeInformation.session_year;
      Saveparam.exam_roll_no =
        this.getCenterTransferExamineeInformation.exam_roll;
      Saveparam.previous_center_code =
        this.getCenterTransferExamineeInformation.center_code;
      Saveparam.previous_center_college_code =
        this.getCenterTransferExamineeInformation.center_college_code;
      Saveparam.current_center_code = this.searchParam.center_code;
      Saveparam.current_center_college_code =
        this.searchParam.center_college_code;
      Saveparam.type = 1;
      console.log("Tanvir submit param", Saveparam);
      this.$store
        .dispatch("saveCenterTransferExaminee", Saveparam)
        .then((data) => {
          console.log("Tanvir data of Center Transfer Examinee Info-->", data);
          this.loading = false;
          this.dialog = true;
          this.fetchCenterTransferExamineeList();
        });
    },

    fetchCenterTransferExamineeList() {
      this.loading = true;
      let param = {};
      param.exam_initiate_id = this.getExamInitiateId;
      param.exam_code = this.getLoggedInExam.code;
      param.exam_year = this.getLoggedInYear.year;
      param.reg_no = this.searchParam.ex_reg_no;
      this.$store
        .dispatch("getCenterTransferExamineeList", param)
        .then((data) => {
          console.log("Tanvir data of Center Transfer Examinee Info-->", data);
          this.loading = false;
        });
    },
  },
};
</script>
<style scoped>
.mycustomExpansionPanel .v-expansion-panel__header {
  border: 1px solid rgb(94, 94, 192) !important;
}

.scroll {
  overflow-y: auto;
}

.catalog-list {
  flex-wrap: wrap;
  padding-top: 1px;
  padding-left: 1px;
  box-sizing: border-box;
  margin: auto;
  box-shadow: inset 0 0 0 1px #8c8c8c;
}

.a {
  border: 1px solid rgb(80, 79, 79);
}

.b {
  border-collapse: collapse;
}
</style>
